import * as React from "react";
import { ShowCookiesConsent } from "../../initializers/cookies_directive";
import { FlashMessageToasts } from "../../utils/toasts";

export const WelcomePage: React.FunctionComponent = (props) => {
  return (
    <>
      <FlashMessageToasts />
      <ShowCookiesConsent />
    </>
  );
};

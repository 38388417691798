/* eslint no-console:0 */
// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";
if (!window._rails_loaded) {
  Rails.start();
}

import "regenerator-runtime/runtime";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "../../stylesheets/welcome.scss";

import * as React from "react";
import "../initializers/init_jquery";
import "../initializers/init_jquery_plugins";

import "@hotwired/turbo-rails";
import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../components/common/app_root";
import moment from "../initializers/moment";
import { WelcomePage } from "../pages/welcome/welcome_page";
import { IconButton, ThemeProvider } from "@mui/material";
import { DefaultTheme } from "../components/styling/theme";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { Close } from "@mui/icons-material";
let welcomeRoot: Root;
function initWelcome() {
  window.moment = moment;

  window.moment.locale(gon.locale);
  // in case there is no I18n defined
  (window.I18n as any) ||= {};
  window.I18n.locale = gon.locale;
  // load jquery ujs to automatically add CSRF token to XHR requests

  const root = document.getElementById("app-root");

  welcomeRoot = createRoot(root);

  welcomeRoot.render(
    <ThemeProvider theme={DefaultTheme}>
      <SnackbarProvider
        autoHideDuration={5000}
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={(snackbarId) => (
          <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
            <Close />
          </IconButton>
        )}
      >
        <WelcomePage />
      </SnackbarProvider>
    </ThemeProvider>,
  );

  $(document).on("turbo:before-render", function () {
    welcomeRoot.unmount();
  });
}

window.SIALOGIC = {
  initWelcome,
};

import { CookieConsent, getCookieConsentValue } from "react-cookie-consent";

import { Link } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import { terms_path } from "../routes";
const cookieName = "sialogic_cookie_consent";
export function ShowCookiesConsent() {
  try {
    const [showConsent, setShowConsent] = React.useState(false);
    React.useEffect(() => {
      const consent = getCookieConsentValue(cookieName);
      setShowConsent(isNil(consent));
    }, []);

    if (showConsent) {
      return (
        <CookieConsent
          style={{ zIndex: 10000 }}
          buttonText={I18n.t(
            "frontend.cookie_banner.cookies_accept_button_text",
          )}
          acceptOnScroll={true}
          acceptOnScrollPercentage={50}
          cookieName={cookieName}
          location="bottom"
          hideOnAccept={true}
        >
          <div>{I18n.t("frontend.cookie_banner.cookies_usage_message")}</div>
          <div>
            {I18n.t(
              "frontend.cookie_banner.find_more_information_in_privacy_policy",
            )}
            :&nbsp;
            <Link href={terms_path({ locale: I18n.locale })}>
              {I18n.t("frontend.cookie_banner.privacy_policy")}
            </Link>
          </div>
        </CookieConsent>
      );
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
}
